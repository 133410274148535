import React, { useState, useEffect, useRef } from 'react';

import { Box, Button, Grid } from '@mui/material';
import FileUpload from './FileUpload';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import api from '../../../../api';
import { formateFileName } from '../../global/logic/Formatters';
import { Signer, Tag, TAG_ACTIONS, TAG_TYPES } from '../EsignInterface';
import EsignTagBar from './EsignTagBar';
import ResizableTag from './tag/ResizableTag';
import { GetDocumentTemplates } from '../logic/HandleTemplate';
import { CreateDocumentFromTemplate } from '../logic/HandleDocument';
import SaveTemplateDialog from './SaveTemplateDialog';
import MyTemplateDialog from './MyTemplateDialog';
import EmailSignerDialog from './EmailSignerDialog';

import * as PDFjs from 'pdfjs-dist/legacy/build/pdf.mjs';
import 'pdfjs-dist/web/pdf_viewer.css';
PDFjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.6.82/legacy/build/pdf.worker.min.mjs`;

interface UploadAndTagProps {
    selectedSigners: Signer[];
    signatureDataUrl: string;
    handleBack: () => void;
    showSnackbar: showSnackbar;
}

const UploadAndTag = ({
    selectedSigners,
    signatureDataUrl,
    handleBack,
    showSnackbar
}: UploadAndTagProps) => {
    const [uploadedFile, setUploadedFile] = useState(null);

    const [documentId, setDocumentId] = useState<string | null>(null);
    const [documentKey, setDocumentKey] = useState<string | null>(null);
    const [fileType, setFileType] = useState('pdf');
    const canvasRefs = useRef([]);
    const [canvasReady, setCanvasReady] = useState(false);
    const [tags, setTags] = useState<Tag[]>([]);
    const [numberOfPages, setNumberOfPages] = useState(0);

    const [myTemplates, setMyTemplates] = useState([]);

    const getMyDocTemplates = async () => {
        const result = await GetDocumentTemplates();
        setMyTemplates(result);
    };

    useEffect(() => {
        getMyDocTemplates();
    }, []);

    const handleFileUpload = (file) => {
        if (
            file.type !== 'application/pdf' &&
            file.type !== 'image/png' &&
            file.type !== 'image/jpeg' &&
            file.type !== 'image/jpg'
        ) {
            showSnackbar(
                `Unsupported file type ${file.type}`,
                // 'PDF, PNG, or JPG (Max. File Size: 30MB)',
                'PDF (Max. File Size: 30MB)',
                'error'
            );
            return;
        }

        // Set file type
        if (file.type === 'application/pdf') {
            setFileType('pdf');
        } else {
            setFileType('image');
        }

        // Prepare FormData
        const formData = new FormData();
        const formattedFileName = formateFileName(file.name);

        formData.append('file', file, formattedFileName);
        formData.append('folder', 'esign');

        api.post('/newDocument', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'file-type': file.type
            }
        }).then((response) => {
            const { signedUrl, documentId, documentKey } = response.data;
            setUploadedFile(signedUrl);
            setDocumentId(documentId);
            setDocumentKey(documentKey);
        });
    };

    const handleSelectTemplate = async (template) => {
        const { documentKey, TemplateTags } = template;

        // create a new Document record using the same documentKey
        const { DocumentId, signedUrl } = await CreateDocumentFromTemplate({
            key: documentKey
        });

        // need to be updated based on the file type
        setFileType('pdf');

        setUploadedFile(signedUrl);
        setDocumentId(DocumentId);

        // set tags based on template tags, and update the DocumentId and SignerId
        setTags(
            TemplateTags.map((tag) => ({
                type: tag.type,
                page: tag.page,
                x: tag.x,
                y: tag.y,
                width: tag.width,
                height: tag.height,
                originalCanvasWidth: tag.originalCanvasWidth,
                originalCanvasHeight: tag.originalCanvasHeight,
                DocumentId: DocumentId,
                SignerId: selectedSigners[0]?.externalId, // default assign to the first signer
                inputData: tag.inputData
            }))
        );
    };

    useEffect(() => {
        if (uploadedFile) {
            if (fileType === 'pdf') {
                const loadingTask = PDFjs.getDocument(uploadedFile);
                loadingTask.promise.then((pdf) => {
                    const numPages = pdf.numPages;
                    setNumberOfPages(numPages);

                    for (let pageNum = 1; pageNum <= numPages; pageNum++) {
                        pdf.getPage(pageNum).then((page) => {
                            const viewport = page.getViewport({ scale: 2 });
                            const canvas = canvasRefs.current[pageNum - 1];

                            if (canvas) {
                                canvas.height = viewport.height;
                                canvas.width = viewport.width;
                                const renderContext = {
                                    canvasContext: canvas.getContext('2d'),
                                    viewport: viewport
                                };

                                // if (canvas.renderTask) {
                                //     canvas.renderTask.cancel();
                                // }

                                page.render(renderContext).promise.then(() => {
                                    setCanvasReady(true);
                                });
                            } else {
                                console.log(
                                    'Canvas is not defined at index:',
                                    pageNum - 1
                                );
                            }
                        });
                    }
                });
            } else if (fileType === 'image') {
                const canvas = canvasRefs.current[0]; // Assuming only one canvas for image
                if (canvas) {
                    const context = canvas.getContext('2d');
                    const img = new Image();
                    img.onload = () => {
                        canvas.width = img.width;
                        canvas.height = img.height;
                        context.drawImage(img, 0, 0, img.width, img.height);
                    };
                    img.src = uploadedFile;
                }
                setNumberOfPages(1); // because image can only have one page
            } else {
                showSnackbar(
                    'Unsupported file type',
                    // 'PDF, PNG, or JPG (Max. File Size: 30MB)',
                    'PDF (Max. File Size: 30MB)',
                    'error'
                );
            }
        }
        // eslint-disable-next-line
    }, [uploadedFile]);

    const onDragOver = (e) => {
        e.preventDefault();
    };

    const onDrop = (e, pageNo) => {
        e.preventDefault();

        // find the grid item where the drop event happened
        const gridItem = e.target.closest('.grid-item');

        // Find the specific canvas within the grid item
        const canvas = gridItem.querySelector('canvas');

        const rect = canvas.getBoundingClientRect();

        const type = e.dataTransfer.getData('type');

        // retrive the offset of the mouse cursor from the top left corner
        const offsetX = parseInt(e.dataTransfer.getData('offsetX'), 10);
        const offsetY = parseInt(e.dataTransfer.getData('offsetY'), 10);

        // retrive the width and height of the signature field
        const width = parseInt(e.dataTransfer.getData('width'), 10);
        const height = parseInt(e.dataTransfer.getData('height'), 10);

        // calculate the new position of the signature: mouse cursor position - offset
        const x = e.clientX - rect.left - offsetX;
        const y = e.clientY - rect.top - offsetY;

        // get the input data, only content tag include Text and MySignature has input data
        const inputData = e.dataTransfer.getData('inputData');

        const tempId = e.dataTransfer.getData('tempId');

        let nextTempId =
            tags && tags.length > 0
                ? Math.max(...tags.map((tag) => Number(tag.tempId))) + 1
                : 1;

        // if it is alreay on the canvas, update the position
        if (tempId !== '') {
            setTags((prevTags) =>
                prevTags.map((tag, i) => {
                    if (Number(tag.tempId) === Number(tempId)) {
                        return {
                            ...tag,
                            page: pageNo,
                            x: x,
                            y: y,
                            width: width,
                            height: height,
                            originalCanvasWidth: canvas.width,
                            originalCanvasHeight: canvas.height
                        };
                    }
                    return tag;
                })
            );
        }
        // if the index does not exsit, means it is a new tag, add the tag to the tags array
        else {
            // Text or MySignature tag is for the user to fill in, no need to assign to a signer
            // If it is not a content tag, assign it to the first signer by default
            const isContentTag =
                type === TAG_TYPES.TEXT || type === TAG_TYPES.MYSIGNATURE;

            const hasDefaultValue = type === TAG_TYPES.CHECKBOX;

            setTags((prevTags) => [
                ...prevTags,
                {
                    tempId: nextTempId,
                    DocumentId: documentId,
                    SignerId: isContentTag
                        ? null
                        : selectedSigners[0]?.externalId,
                    type: type,
                    page: pageNo,
                    x: x,
                    y: y,
                    width: width,
                    height: height,
                    originalCanvasWidth: canvas.width,
                    originalCanvasHeight: canvas.height,
                    inputData:
                        isContentTag || hasDefaultValue ? inputData : null
                    // type === TAG_TYPES.MYSIGNATURE ? signatureDataUrl : null
                }
            ]);
        }
    };

    console.log('tags', tags);
    const [openEmailDialog, setOpenEmailDialog] = useState(false);
    const [openSaveTemplateDialog, setOpenSaveTemplateDialog] = useState(false);

    const handleClickFinishAndSend = () => {
        setOpenEmailDialog(true);
    };

    const handleClickSaveDocTemplate = () => {
        setOpenSaveTemplateDialog(true);
    };

    const handleSelectTagTemplate = (template) => {
        setTags(
            template?.TemplateTags?.map((tag) => ({
                type: tag.type,
                page: tag.page,
                x: tag.x,
                y: tag.y,
                width: tag.width,
                height: tag.height,
                originalCanvasWidth: tag.originalCanvasWidth,
                originalCanvasHeight: tag.originalCanvasHeight,
                DocumentId: documentId,
                SignerId: selectedSigners[0]?.externalId, // default assign to the first signer
                inputData: tag.inputData
            }))
        );
    };

    const [openMyTemplateDialog, setOpenMyTemplateDialog] = useState(false);

    return (
        <>
            {!uploadedFile ? (
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Button variant="outlined" onClick={handleBack}>
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setOpenMyTemplateDialog(true);
                            }}
                        >
                            Document Template
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <FileUpload onFileSelect={handleFileUpload} />
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <EsignTagBar
                        handleClickSaveAsTemplate={handleClickSaveDocTemplate}
                        handleClickFinishAndSend={handleClickFinishAndSend}
                        handleSelectTagTemplate={handleSelectTagTemplate}
                    />
                    {/* // display all pdf canvas    */}
                    {fileType === 'pdf' ? (
                        Array.from({ length: numberOfPages }, (_, pageNo) => (
                            <Grid
                                item
                                xs={12}
                                style={{ position: 'relative' }}
                                className="grid-item" // do not remove this class, this is used to find the grid item where the drop event happened
                            >
                                <Box
                                    className="document-container"
                                    onDragOver={onDragOver}
                                    onDrop={(e) => onDrop(e, pageNo)}
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: 'auto'
                                    }}
                                >
                                    <canvas
                                        key={pageNo}
                                        ref={(el) =>
                                            (canvasRefs.current[pageNo] = el)
                                        }
                                    ></canvas>

                                    {/* // display signature on specific page and position */}
                                    {canvasReady &&
                                        tags.map((tag, index) => {
                                            if (tag.page === pageNo) {
                                                // Calculate the scaling factor based on the current and original canvas sizes.
                                                const scaleX =
                                                    canvasRefs.current[pageNo]
                                                        .width /
                                                    tag.originalCanvasWidth;
                                                const scaleY =
                                                    canvasRefs.current[pageNo]
                                                        .height /
                                                    tag.originalCanvasHeight;

                                                // Adjust position and size.
                                                const adjustedPosition = {
                                                    x: tag.x * scaleX,
                                                    y: tag.y * scaleY,
                                                    width: tag.width * scaleX,
                                                    height: tag.height * scaleY
                                                };

                                                return (
                                                    <ResizableTag
                                                        key={index}
                                                        index={index}
                                                        action={
                                                            TAG_ACTIONS.MODIFY
                                                        }
                                                        tag={tag}
                                                        tags={tags}
                                                        setTags={setTags}
                                                        width={
                                                            adjustedPosition.width
                                                        }
                                                        height={
                                                            adjustedPosition.height
                                                        }
                                                        x={adjustedPosition.x}
                                                        y={adjustedPosition.y}
                                                        signatureDataUrl={
                                                            signatureDataUrl
                                                        }
                                                        selectedSigners={
                                                            selectedSigners
                                                        }
                                                    />
                                                );
                                            }
                                            return null;
                                        })}
                                </Box>
                            </Grid>
                        ))
                    ) : (
                        <Grid item xs={12}>
                            <Box
                                className="pdf-container"
                                onDragOver={onDragOver}
                                onDrop={(e) => onDrop(e, 0)}
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: 'auto'
                                }}
                            >
                                <canvas
                                    ref={(el) => (canvasRefs.current[0] = el)}
                                    className="image-canvas"
                                ></canvas>
                            </Box>
                        </Grid>
                    )}

                    <EmailSignerDialog
                        openEmailDialog={openEmailDialog}
                        setOpenEmailDialog={setOpenEmailDialog}
                        documentId={documentId}
                        signers={selectedSigners}
                        tags={tags}
                        showSnackbar={showSnackbar}
                    />

                    <SaveTemplateDialog
                        openSaveTemplateDialog={openSaveTemplateDialog}
                        setOpenSaveTemplateDialog={setOpenSaveTemplateDialog}
                        documentKey={documentKey}
                        documentId={documentId}
                        tags={tags}
                        showSnackbar={showSnackbar}
                    />
                </Grid>
            )}
            <MyTemplateDialog
                type="document"
                open={openMyTemplateDialog}
                setOpen={setOpenMyTemplateDialog}
                myTemplates={myTemplates}
                handleSelectTemplate={handleSelectTemplate}
            />
        </>
    );
};

export default UploadAndTag;
