import api from '../../../../api';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { CreateSignerFormInput, Signer } from '../EsignInterface';

interface UpsertSignerProps {
    formData: CreateSignerFormInput;
    showSnackbar: showSnackbar;
}

export const UpsertSigner = async ({ formData, showSnackbar }: UpsertSignerProps) => {
    try {
        const response = await api.post('/upsertSigner', formData);
        return response.data;
    } catch (error) {
        showSnackbar(
            'Whoops! Something went wrong on our end.',
            'Please contact your IT department.',
            'error'
        );

        return error;
    }
};

export const GetAllSigners = async () => {
    try {
        const response = await api.get('/getAllSigners');
        return response.data;
    } catch (error) {
        return error;
    }
};
