import { useContext, useEffect, useRef } from 'react';
import { User } from '../../../global/interfaces/GeneralInterface';
import { UserContext } from '../../../../main/Main';
import GetUserSignature from '../../../global/databaseLogic/GetUserSignature';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Grid, IconButton } from '@mui/material';
import { Clear } from '@mui/icons-material';
import Paper from '../../../global/Paper';
import api from '../../../../../api';

const UserSignature = ({
    userId,
    userDetails,
    setUserDetails,
    showSnackbar
}) => {
    const currentUser = useContext<User>(UserContext);
    let sigCanvas = useRef<SignatureCanvas>(null);

    useEffect(() => {
        GetUserSignature({
            id: parseInt(userId),
            sigCanvas: sigCanvas
        });
    }, [userId]);

    const clearSignature = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
        }
    };

    const formatIntoPng = () => {
        if (sigCanvas.current) {
            const dataURL = sigCanvas.current.toDataURL();
            setUserDetails({ ...userDetails, signature: dataURL });
            return dataURL;
        }
    };

    const handleClickSabSignature = async () => {
        await api
            .put(`updateUserSignature/${userDetails.id}`, userDetails)
            .then((res) => {
                if (res.status === 200) {
                    showSnackbar(
                        'User signature updated.',
                        'The page will now refresh.'
                    );
                    setTimeout(() => window.location.reload(), 1500);
                } else {
                    showSnackbar(
                        'Whoops! Something went wrong on our end.',
                        'Please contact your IT department.',
                        'error'
                    );
                }
            });
    };
    return userDetails.id === currentUser.id ? (
        <>
            <Paper>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {Object.hasOwn(userDetails, 'id') &&
                        currentUser.id === userDetails.id ? (
                            <Grid item xs={12} textAlign={'center'}>
                                User Signature (This is used to prefill
                                e-signature.){' '}
                                <IconButton onClick={() => clearSignature()}>
                                    <Clear />
                                </IconButton>
                                <br />
                                <SignatureCanvas
                                    penColor={'black'}
                                    canvasProps={{
                                        width: 350,
                                        height: 150,
                                        className: 'sigCanvas',
                                        style: {
                                            border: '1px solid black'
                                        }
                                    }}
                                    ref={sigCanvas}
                                    onEnd={() => formatIntoPng()}
                                />
                                <br />
                                <Button
                                    variant="contained"
                                    onClick={() => handleClickSabSignature()}
                                >
                                    Save Signature
                                </Button>
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
            </Paper>
            <br />
        </>
    ) : null;
};
export default UserSignature;
