import { useEffect, useRef, useState } from 'react';
import PageWrapper from '../global/PageWrapper';
import { Grid } from '@mui/material';
import { GetLinkedTags } from './logic/HandleTag';
import { GetDocumentUrl } from './logic/HandleDocument';
import NewSignerAppBar from './components/NewSignerAppBar';
import { Tag, TAG_ACTIONS } from './EsignInterface';
import ResizableTag from './components/tag/ResizableTag';
import FileLoadingPage from './components/FileLoadingPage';
import CustomerSubmitSuccessPage from './components/CustomerSubmitSuccessPage';
import ClearTokenOnExit from './logic/CleanTokenOnExit';
// import { getDocument } from 'pdfjs-dist';
import * as PDFjs from 'pdfjs-dist/legacy/build/pdf.mjs';

PDFjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.6.82/legacy/build/pdf.worker.min.mjs`;

const SignerPage = () => {
    const [fileUrl, setFileUrl] = useState(null);
    const [status, setStatus] = useState(null);
    const canvasRefs = useRef([]);
    const tagRefs = useRef([]);

    const [numberOfPages, setNumberOfPages] = useState(0);
    const [sortedTags, setSortedTags] = useState<Tag[]>([]);
    const [currentTagIndex, setCurrentTagIndex] = useState(-1);
    const [isSubmitSuccess, setIsSubmitSuccess] = useState<boolean>(false);
    const [pdfLoaded, setPdfLoaded] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const documentId = urlParams.get('document');
    const signerId = urlParams.get('signer');

    // get the file url
    const getFileUrl = async () => {
        const { signedUrl, docStatus } = await GetDocumentUrl(documentId);
        setFileUrl(signedUrl);
        setStatus(docStatus);
    };

    // get all linked Tags
    const getTags = async () => {
        const linkedTags = await GetLinkedTags({
            DocumentId: documentId,
            SignerId: signerId
        });

        // sort tags by page number, then y, then x
        const sorted = linkedTags.sort((a, b) => {
            if (a.page === b.page) {
                if (a.y === b.y) {
                    return a.x - b.x;
                }
                return a.y - b.y;
            }
            return a.page - b.page;
        });

        setSortedTags(sorted);
    };

    useEffect(() => {
        getFileUrl();
        getTags();
        // eslint-disable-next-line
    }, [documentId, signerId]);

    const [canvasReady, setCanvasReady] = useState(false);

    useEffect(() => {
        if (fileUrl) {
            const loadingTask = PDFjs.getDocument(fileUrl);

            loadingTask.promise.then((pdf) => {
                const numPages = pdf.numPages;
                setNumberOfPages(numPages);

                for (let pageNum = 1; pageNum <= numPages; pageNum++) {
                    pdf.getPage(pageNum).then((page) => {
                        const scale = 2;
                        const viewport = page.getViewport({ scale });

                        const canvas = canvasRefs.current[pageNum - 1];

                        if (canvas) {
                            canvas.height = viewport.height;
                            canvas.width = viewport.width;
                            const renderContext = {
                                canvasContext: canvas.getContext('2d'),
                                viewport: viewport
                            };
                            page.render(renderContext).promise.then(() => {
                                setCanvasReady(true);
                            });
                        } else {
                            console.log(
                                'Canvas is not defined at index:',
                                pageNum - 1
                            );
                        }
                    });
                }

                setPdfLoaded(true);
            });
        }
    }, [fileUrl]);

    const canvasHeight = canvasRefs.current[0]?.height;

    const [adjustedTags, setAdjustedTags] = useState([]);
    useEffect(() => {
        const recalculateTags = () => {
            const newAdjustedTags = sortedTags.map((tag) => {
                const pageNo = tag.page;
                const canvas = canvasRefs.current[pageNo];
                if (canvas) {
                    const rect = canvas.getBoundingClientRect();

                    // Calculate the scaling factor based on the current and original canvas sizes.
                    const scaleX = rect.width / tag.originalCanvasWidth;
                    const scaleY = rect.height / tag.originalCanvasHeight;

                    // Adjust position and size based on the scaling factor.
                    return {
                        ...tag,
                        adjustedPosition: {
                            x: tag.x * scaleX,
                            y: tag.y * scaleY,
                            width: tag.width * scaleX,
                            height: tag.height * scaleY
                        }
                    };
                }
                return tag;
            });

            setAdjustedTags(newAdjustedTags);
        };

        // calculate the tags when the canvas is ready
        const handleResize = () => {
            recalculateTags();
        };

        if (canvasReady) {
            window.addEventListener('resize', handleResize);
            recalculateTags(); // calculate the tags when the canvas is ready
        }

        // remove resize event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [canvasReady, sortedTags]);

    useEffect(() => {
        if (tagRefs.current[currentTagIndex]) {
            // scroll the current tag into view
            tagRefs.current[currentTagIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });

            // Set background color to yellow for current active tag
            tagRefs.current[currentTagIndex].style.backgroundColor = 'yellow';
        }
    }, [currentTagIndex]);

    // Remove auth token from local storage when the user closes the tab or refreshes the page, user will have to sign again
    useEffect(() => {
        ClearTokenOnExit();
    }, []);

    if (isSubmitSuccess || status === 'signed') {
        return <CustomerSubmitSuccessPage />;
    }

    if (!pdfLoaded) {
        return <FileLoadingPage />;
    }

    return (
        <PageWrapper notVisible={true}>
            <Grid container spacing={2}>
                <NewSignerAppBar
                    currentTagIndex={currentTagIndex}
                    setCurrentTagIndex={setCurrentTagIndex}
                    tags={sortedTags}
                    canvasHeight={canvasHeight}
                    setIsSubmitSuccess={setIsSubmitSuccess}
                />
                {/* // display all pdf canvas    */}
                {Array.from({ length: numberOfPages }, (_, pageNo) => (
                    <Grid item xs={12} style={{ position: 'relative' }}>
                        <div style={{ position: 'relative' }}>
                            <canvas
                                key={pageNo}
                                ref={(el) => (canvasRefs.current[pageNo] = el)}
                                className="pdf-canvas"
                                style={{
                                    width: '100%',
                                    height: 'auto'
                                }}
                            ></canvas>
                            {canvasReady &&
                                adjustedTags.map((tag, index) => {
                                    if (tag.page === pageNo) {
                                        return (
                                            <ResizableTag
                                                key={index}
                                                ref={(el) =>
                                                    (tagRefs.current[index] =
                                                        el)
                                                }
                                                index={index}
                                                action={TAG_ACTIONS.FILL}
                                                tag={sortedTags[index]}
                                                tags={sortedTags}
                                                setTags={setSortedTags}
                                                width={
                                                    tag.adjustedPosition.width
                                                }
                                                height={
                                                    tag.adjustedPosition.height
                                                }
                                                x={tag.adjustedPosition.x}
                                                y={tag.adjustedPosition.y}
                                                currentTagIndex={
                                                    currentTagIndex
                                                }
                                                setCurrentTagIndex={
                                                    setCurrentTagIndex
                                                }
                                            />
                                        );
                                    }
                                    return null;
                                })}
                        </div>
                    </Grid>
                ))}
            </Grid>
        </PageWrapper>
    );
};

export default SignerPage;
