import { useState } from 'react';

import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Radio
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@material-ui/core';

const MyTemplateDialog = ({
    type,
    open,
    setOpen,
    myTemplates,
    handleSelectTemplate
}) => {
    const onClose = () => {
        setOpen(false);
    };

    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const handleSelect = (template) => {
        setSelectedTemplate(template);
        handleSelectTemplate(template);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} fullScreen scroll="paper">
            <DialogTitle sx={{ m: 0, p: 2 }}>
                {type === 'tag' ? 'Tag ' : 'Document '}Templates
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500]
                })}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                {myTemplates.length === 0 && (
                    <>
                        <Typography variant="h6">
                            {type === 'tag'
                                ? "You haven't created any tag templates yet."
                                : "You haven't created any document templates yet."}
                        </Typography>
                        <Typography variant="body1">
                            {type === 'tag'
                                ? "Upload a document, add tags, and then choose 'Save Tags Only' when saving as a template to create a tag template."
                                : "Upload a document, add tags, and then choose 'Save Document and Tags' when saving as a template to create a document template."}
                        </Typography>
                    </>
                )}

                <List>
                    {myTemplates.map((template, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemButton
                                onClick={() => handleSelect(template)}
                                dense
                            >
                                <ListItemIcon>
                                    <Radio
                                        edge="start"
                                        checked={selectedTemplate === template}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': template.id
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={template.name} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    );
};

export default MyTemplateDialog;
