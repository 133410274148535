import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
    Autocomplete,
    Button,
    createFilterOptions,
    Grid,
    TextField
} from '@mui/material';
import { GetAllSigners, UpsertSigner } from '../logic/HandleSigner';
import { Signer } from '../EsignInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { useCallback, useEffect, useState } from 'react';

const validateSchema = yup.object().shape({
    signers: yup.array().of(
        yup.object().shape({
            fullName: yup.string().required('Full Name is required'),
            email: yup
                .string()
                .email('Invalid Email Address')
                .required('Email is required'),
            externalId: yup.string().nullable()
        })
    )
});

interface AddSignerProps {
    selectedSigners: Signer[];
    setSelectedSigners: React.Dispatch<React.SetStateAction<Signer[]>>;
    handleNext: () => void;
    showSnackbar: showSnackbar;
}

const AddSigner = ({
    selectedSigners,
    setSelectedSigners,
    handleNext,
    showSnackbar
}: AddSignerProps) => {
    const [existingSigners, setExistingSigners] = useState<Signer[]>([]);

    const {
        control,
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors }
    } = useForm({
        defaultValues: {
            signers: [{ fullName: '', email: '', externalId: '' }]
        },
        resolver: yupResolver(validateSchema),
        mode: 'onTouched'
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'signers'
    });

    const watchSigners = watch('signers');

    useEffect(() => {
        const getAllSigners = async () => {
            const res = await GetAllSigners();
            setExistingSigners(res);
        };
        getAllSigners();
    }, []);

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option: Signer) => `${option.fullName} ${option.email}`
    });

    const handleSelectSigner = (
        index: number,
        field: string,
        value: Signer | null
    ) => {
        if (value) {
            setValue(`signers.${index}.fullName`, value.fullName);
            setValue(`signers.${index}.email`, value.email);
            setValue(`signers.${index}.externalId`, value.externalId);
        } else {
            setValue(`signers.${index}.fullName`, '');
            setValue(`signers.${index}.email`, '');
            setValue(`signers.${index}.externalId`, '');
        }
    };

    // when user enter full name or email, update the value in form
    const handleUserInput = (index: number, field: string, value: string) => {
        setValue(
            `signers.${index}.${field as 'fullName' | 'email' | 'externalId'}`,
            value
        );
    };

    const handleClickAddNew = () => {
        const newSigner = watchSigners[watchSigners.length - 1];

        if (newSigner.fullName && newSigner.email) {
            setSelectedSigners([...selectedSigners, newSigner as Signer]);
            append({ fullName: '', email: '', externalId: '' });
        }
    };

    const handleClickNext = async () => {
        // Save the last signer to selectedSigners
        const lastSigner = watchSigners[watchSigners.length - 1];
        if (lastSigner.fullName && lastSigner.email) {
            setSelectedSigners((prev) => [...prev, lastSigner as Signer]);
        }

        // if signer has an externalId, it means it's an existing signer, only add to selectedSigners
        // if signer has no externalId, it means it's a new signer, upsert new signer and add to selectedSigners
        const updatedSigners = await Promise.all(
            watchSigners.map(async (signer) => {
                if (!signer.externalId) {
                    const newSigner = await UpsertSigner({
                        formData: {
                            fullName: signer.fullName,
                            email: signer.email
                        },
                        showSnackbar
                    });

                    if (newSigner) {
                        return { ...signer, externalId: newSigner.externalId };
                    }
                }
                return signer;
            })
        );

        setSelectedSigners(updatedSigners as Signer[]);
        handleNext();
    };

    const getAddButtonText = useCallback(() => {
        const totalSigners = selectedSigners.length;
        const ordinal = ['2nd', '3rd'];
        return `Add ${ordinal[totalSigners] || `${totalSigners + 2}th`} Signer`;
    }, [selectedSigners.length]);

    return (
        <Grid container spacing={2}>
            {fields.map((field, index) => (
                <Grid item xs={12} key={field.id}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            Signer {index + 1}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name={`signers.${index}.fullName`}
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        options={existingSigners}
                                        getOptionLabel={(option) =>
                                            typeof option === 'string'
                                                ? option
                                                : `${option.fullName}: ${option.email}`
                                        }
                                        filterOptions={filterOptions}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Full Name"
                                                error={
                                                    !!errors.signers?.[index]
                                                        ?.fullName
                                                }
                                                helperText={
                                                    errors.signers?.[index]
                                                        ?.fullName?.message
                                                }
                                            />
                                        )}
                                        onChange={(_, value) =>
                                            handleSelectSigner(
                                                index,
                                                'fullName',
                                                value as Signer
                                            )
                                        }
                                        onInputChange={(_, value) => {
                                            handleUserInput(
                                                index,
                                                'fullName',
                                                value as string
                                            );
                                        }}
                                        freeSolo
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name={`signers.${index}.email`}
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        options={existingSigners}
                                        getOptionLabel={(option) =>
                                            typeof option === 'string'
                                                ? option
                                                : `${option.fullName}: ${option.email}`
                                        }
                                        filterOptions={filterOptions}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Email"
                                                error={
                                                    !!errors.signers?.[index]
                                                        ?.email
                                                }
                                                helperText={
                                                    errors.signers?.[index]
                                                        ?.email?.message
                                                }
                                            />
                                        )}
                                        onChange={(_, value) =>
                                            handleSelectSigner(
                                                index,
                                                'email',
                                                value as Signer
                                            )
                                        }
                                        onInputChange={(_, value) => {
                                            handleUserInput(
                                                index,
                                                'email',
                                                value as string
                                            );
                                        }}
                                        freeSolo
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ))}
            <Grid item xs={12}>
                <Button
                    onClick={handleSubmit(handleClickAddNew)}
                    variant="outlined"
                    sx={{ mr: 2 }}
                >
                    {getAddButtonText()}
                </Button>
                <Button
                    onClick={handleSubmit(handleClickNext)}
                    variant="contained"
                >
                    Next
                </Button>
            </Grid>
        </Grid>
    );
};

export default AddSigner;
