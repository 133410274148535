import api from '../../../../api';

const HandleEmailSigner = async ({
    accessCode,
    signers,
    baseURL,
    documentId,
    tags,
    newStatus,
    showSnackbar
}) => {
    try {
        const emailRes = await api.post('/emailSigner', {
            tags,
            signers,
            baseURL,
            documentId,
            newStatus,
            accessCode
        });

        if (emailRes.status === 200) {
            showSnackbar(
                'Email sent successfully!',
                'The page will now refresh.',
                'success'
            );

            // reload the page after 2 seconds
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } else {
            showSnackbar(
                'Email sending failed',
                'Please try again later.',
                'error'
            );
        }
    } catch (error) {
        showSnackbar(
            'Whoops! Something went wrong on our end.',
            'Please contact your IT department.',
            'error'
        );
    }
};

export default HandleEmailSigner;
