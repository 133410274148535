import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@mui/material';
import HandleEmailSigner from '../logic/HandleEmailSigner';
import { DOCUMENT_STATUS, Signer, Tag } from '../EsignInterface';
import { useState } from 'react';

interface EmailSignerDialogProps {
    openEmailDialog: boolean;
    setOpenEmailDialog: (open: boolean) => void;
    documentId: string;
    signers: Signer[];
    showSnackbar: any;
    tags?: Tag[]; // only when first time send email need tags
}

const EmailSignerDialog = ({
    openEmailDialog,
    setOpenEmailDialog,
    documentId,
    signers,
    showSnackbar,
    tags
}: EmailSignerDialogProps) => {
    //default random generate 6 character access code
    const [accessCode, setAccessCode] = useState(
        Math.random().toString(36).substring(2, 8)
    );

    const baseURL = window.location.origin;

    const handleClickSendEmail = async () => {
        await HandleEmailSigner({
            accessCode,
            signers,
            baseURL,
            documentId,
            tags,
            newStatus: DOCUMENT_STATUS.WAITINGIGNATURE,
            showSnackbar
        });

        setOpenEmailDialog(false);
    };

    const onClose = () => {
        setAccessCode(Math.random().toString(36).substring(2, 8));
        setOpenEmailDialog(false);
    };

    return (
        <Dialog open={openEmailDialog} onClose={onClose}>
            <DialogTitle>Email Customer</DialogTitle>
            <DialogContent>
                An email with a link to review and sign the document will be
                sent to the following recipients:
                <br />
                {signers.map((signer) => (
                    <div key={signer.externalId}>
                        {' '}
                        <b>{signer.fullName} </b> at the email address{' '}
                        <b>{signer.email}</b>
                    </div>
                ))}
                <br />
                Please note that the link will remain valid for 48 hours only.
                The email will also include an unique access code to verify
                identity:
                <TextField
                    variant="standard"
                    fullWidth
                    label="Access Code"
                    value={accessCode}
                    onChange={(e) => setAccessCode(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button variant="contained" onClick={handleClickSendEmail}>
                    Send Email
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EmailSignerDialog;
