import { Grid, TableRow, IconButton, Tooltip } from '@mui/material';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { useState } from 'react';
import { DOCUMENT_STATUS } from '../EsignInterface';

// COMPONENTS
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCell from '../../global/tableComponents/DataCell';
import { DateFormatter } from '../../global/logic/Formatters';
import TableSearch from '../../global/tableComponents/TableSearch';
import SendIcon from '@mui/icons-material/Send';
import EmailSignerDialog from './EmailSignerDialog';

interface PendingSignTabContentProps {
    showSnackbar: showSnackbar;
}

const clearedSort = ['id', 'DESC'];

const PendingSignTabContent = ({
    showSnackbar
}: PendingSignTabContentProps) => {
    const [resultsList, setResultsList] = useState([]);

    // Columns for the data table
    const columns = [
        { id: 0, label: 'ID', name: 'id', width: 100 },
        { id: 1, label: 'Signer', width: 400 },
        { id: 3, label: 'Document', width: 300 },
        { id: 4, label: 'Sent at', width: 200 },
        { id: 5, label: 'Resend', width: 100 }
    ];

    const handleRequestCreate = () => {
        let apiString = `&status=${DOCUMENT_STATUS.WAITINGIGNATURE}&status=${DOCUMENT_STATUS.PARTIALLY_SIGNED}&checkEmpty=true`;
        return apiString;
    };

    const [openResendDialog, setOpenResendDialog] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedSigners, setSelectedSigners] = useState([]);

    const handleResendEmail = (row) => {
        setSelectedDocument(row);
        const uniqueSigners = row.Tags.reduce((acc, tag) => {
            if (!acc.some((signer) => signer.externalId === tag.SignerId)) {
                acc.push(tag.Signer);
            }
            return acc;
        }, []);
        setSelectedSigners(uniqueSigners);
        setOpenResendDialog(true);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <TableSearch
                    searchTitle="Search by id, signer name, email or document name"
                    showFilter={false}
                    showPagination={true}
                    showPaper={true}
                    sort={clearedSort}
                    handleRequestCreate={handleRequestCreate}
                    route="esignDocumentSearch"
                    setResultsList={setResultsList}
                ></TableSearch>
                <Paper>
                    <DataTable columns={columns}>
                        {resultsList.map((row) => {
                            const uniqueSigners = row.Tags.reduce(
                                (acc, tag) => {
                                    if (
                                        !acc.some(
                                            (signer) =>
                                                signer.externalId ===
                                                tag.SignerId
                                        )
                                    ) {
                                        acc.push(tag.Signer);
                                    }
                                    return acc;
                                },
                                []
                            );

                            return (
                                <TableRow key={row.id}>
                                    <DataCell>{row.id}</DataCell>
                                    <DataCell>
                                        {uniqueSigners.map((signer) => (
                                            <div key={signer.id}>
                                                {signer.fullName}:{' '}
                                                {signer.email}
                                            </div>
                                        ))}
                                    </DataCell>
                                    <DataCell> {row.fileName}</DataCell>
                                    <DataCell>
                                        {DateFormatter(row.sentAt)}
                                    </DataCell>
                                    <DataCell>
                                        {row.expiredAt && (
                                            <Tooltip
                                                title="The link in the email has expired. Click to resend the email."
                                                placement="left"
                                            >
                                                <span>
                                                    <IconButton
                                                        onClick={() =>
                                                            handleResendEmail(
                                                                row
                                                            )
                                                        }
                                                    >
                                                        <SendIcon />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        )}
                                    </DataCell>
                                </TableRow>
                            );
                        })}
                    </DataTable>
                </Paper>
            </Grid>
            <EmailSignerDialog
                openEmailDialog={openResendDialog}
                setOpenEmailDialog={setOpenResendDialog}
                documentId={selectedDocument?.externalId}
                signers={selectedSigners}
                showSnackbar={showSnackbar}
            />
        </Grid>
    );
};

export default PendingSignTabContent;
