// IMPORTS
import { useState, useEffect } from 'react';
import {
    Typography,
    Grid,
    TextField,
    MenuItem,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Chip,
    FormLabel
} from '@mui/material';
import { useParams } from 'react-router-dom';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import Fab from '../../global/Fab';
// LOGIC
import HandlePreviewReplacement from './logic/HandlePreviewReplacement';
import HandeAddCodeToBody from './logic/HandleAddCodeToBody';
import AddNewTemplate from './logic/AddNewTemplate';
import GetTemplate from './logic/GetTemplate';
import UpdateTemplate from './logic/UpdateTemplate';
// INTERFACES
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { Template } from '../../global/interfaces/GeneralInterface';
import { withSnackbar } from '../../global/WrappingSnackbar';

const ViewTemplate = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const { id } = useParams<{ id: string }>();
    const params = new URLSearchParams(window.location.search); // Template type is stored here
    const templateType = params.get('type');

    const [readOnly, setReadOnly] = useState(false);
    const [loading, setLoading] = useState(false);

    const [template, setTemplate] = useState<Template>({
        subject: null,
        department: null,
        messageBody: '',
        type: null
    });
    const [templateError, setTemplateError] = useState({
        subject: false,
        department: false,
        messageBody: false,
        type: false
    });

    // If there is an id (not on the add page) get the template data from the server
    useEffect(() => {
        if (id) {
            GetTemplate(
                parseInt(id),
                templateType,
                setTemplate,
                setReadOnly,
                showSnackbar
            );
        }
        // eslint-disable-next-line
    }, [id]);

    return (
        <PageWrapper>
            <Typography variant="h4">
                {id ? template.subject : 'New Template'}
            </Typography>
            <br />
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {/* Subject / Title */}
                        <TextField
                            fullWidth
                            disabled={readOnly}
                            label="Title / Subject"
                            InputLabelProps={{ shrink: true }}
                            value={template.subject}
                            onChange={(e) =>
                                setTemplate({
                                    ...template,
                                    subject: e.target.value
                                })
                            }
                            placeholder="Backorder Collection Notice"
                            error={templateError.subject}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        {/* Department */}
                        <TextField
                            fullWidth
                            select
                            disabled={readOnly}
                            label="Department"
                            InputLabelProps={{ shrink: true }}
                            value={template.department}
                            onChange={(e) =>
                                setTemplate({
                                    ...template,
                                    department: e.target.value
                                })
                            }
                            placeholder="Parts and Accessories"
                            error={templateError.department}
                        >
                            <MenuItem value="Parts and Accessories">
                                Parts and Accessories
                            </MenuItem>
                            <MenuItem value="Service">Service</MenuItem>
                            <MenuItem value="Sales">Sales</MenuItem>
                            <MenuItem value="Online">Online</MenuItem>
                            <MenuItem value="General">General</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={2}>
                        {/* Type - Should be disabled if viewing a template as this cannot be changed */}
                        <FormControl error={templateError.type}>
                            <FormLabel>Template Type</FormLabel>
                            <RadioGroup
                                value={template.type}
                                onChange={(e) =>
                                    setTemplate({
                                        ...template,
                                        type: e.target.value
                                    })
                                }
                                row
                            >
                                <FormControlLabel
                                    value="Text Message"
                                    control={
                                        <Radio disabled={id ? true : false} />
                                    }
                                    label="Text Message"
                                />
                                <FormControlLabel
                                    value="Email"
                                    control={
                                        <Radio disabled={id ? true : false} />
                                    }
                                    label="Email"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>
            <br />
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {/* Message Body */}
                        <TextField
                            fullWidth
                            multiline
                            rows={12}
                            label="Message Body"
                            disabled={readOnly}
                            InputLabelProps={{ shrink: true }}
                            value={template.messageBody}
                            onChange={(e) =>
                                setTemplate({
                                    ...template,
                                    messageBody: e.target.value
                                })
                            }
                            placeholder={`Hi [customerName],\n\nYour order [proformaNumber] is ready to be collected at our [businessStore] store. Please see our business hours below for collection times.\n\n[businessHours]\n\nPlease call [businessPhoneNumber] if you have any questions.\n\nThank-you!\nBrisbane Motorcycles`}
                            error={templateError.messageBody}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {/* Message Body Preview */}
                        <TextField
                            fullWidth
                            multiline
                            rows={12}
                            label="Message Body Preview"
                            disabled
                            InputLabelProps={{ shrink: true }}
                            value={HandlePreviewReplacement(
                                template.messageBody
                            )}
                            placeholder={`Hi Frodo,\n\nYour order 1234 is ready to be collected at our Mordor store. Please see our business hours below for collection times.\n\nMonday - Friday: 8am - 5:30pm\nSaturday: 8am - 4pm\nSunday: Closed\n\nPlease call 3876 2745 if you have any questions.\n\nThank-you!\nBrisbane Motorcycles`}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <br />
            <Paper>
                {/* Substitution codes - make it easier for the user to put in dynamic */}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" align="left">
                            <b>Available Codes</b>
                        </Typography>
                        <br />

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1" align="left">
                                    <Chip
                                        onClick={() =>
                                            HandeAddCodeToBody(
                                                readOnly,
                                                '[customerName]',
                                                template,
                                                setTemplate
                                            )
                                        }
                                        label={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                            >
                                                [customerName]
                                            </Typography>
                                        }
                                    />
                                    &nbsp; Will insert the customer name when
                                    used in a proforma / service / vehicle sale.
                                </Typography>
                                <br />
                                <Typography variant="subtitle1" align="left">
                                    <Chip
                                        onClick={() =>
                                            HandeAddCodeToBody(
                                                readOnly,
                                                '[businessStore]',
                                                template,
                                                setTemplate
                                            )
                                        }
                                        label={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                            >
                                                [businessStore]
                                            </Typography>
                                        }
                                    />
                                    &nbsp; Will insert the store that the sale
                                    is assigned to.
                                </Typography>
                                <br />
                                <Typography variant="subtitle1" align="left">
                                    <Chip
                                        onClick={() =>
                                            HandeAddCodeToBody(
                                                readOnly,
                                                '[businessPhoneNumber]',
                                                template,
                                                setTemplate
                                            )
                                        }
                                        label={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                            >
                                                [businessPhoneNumber]
                                            </Typography>
                                        }
                                    />
                                    &nbsp; Will insert the store's phone number
                                    that the sale is assigned to.
                                </Typography>
                                <br />
                                <Typography variant="subtitle1" align="left">
                                    <Chip
                                        onClick={() =>
                                            HandeAddCodeToBody(
                                                readOnly,
                                                '[businessHours]',
                                                template,
                                                setTemplate
                                            )
                                        }
                                        label={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                            >
                                                [businessHours]
                                            </Typography>
                                        }
                                    />
                                    &nbsp; Will insert the store's trading hours
                                    that the sale is assigned to.
                                </Typography>
                                <br />
                                <Typography variant="subtitle1" align="left">
                                    <Chip
                                        onClick={() =>
                                            HandeAddCodeToBody(
                                                readOnly,
                                                '[bikeMakeModel]',
                                                template,
                                                setTemplate
                                            )
                                        }
                                        label={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                            >
                                                [bikeMakeModel]
                                            </Typography>
                                        }
                                    />
                                    &nbsp; Will insert the make and model of a
                                    bike from a vehicle sale.
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1" align="left">
                                    <Chip
                                        onClick={() =>
                                            HandeAddCodeToBody(
                                                readOnly,
                                                '[proformaNumber]',
                                                template,
                                                setTemplate
                                            )
                                        }
                                        label={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                            >
                                                [proformaNumber]
                                            </Typography>
                                        }
                                    />
                                    &nbsp; Will insert the proforma number if
                                    applicable.
                                </Typography>
                                <br />
                                <Typography variant="subtitle1" align="left">
                                    <Chip
                                        onClick={() =>
                                            HandeAddCodeToBody(
                                                readOnly,
                                                '[serviceNumber]',
                                                template,
                                                setTemplate
                                            )
                                        }
                                        label={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                            >
                                                [serviceNumber]
                                            </Typography>
                                        }
                                    />
                                    &nbsp; Will insert the service number if
                                    applicable.
                                </Typography>
                                <br />
                                <Typography variant="subtitle1" align="left">
                                    <Chip
                                        onClick={() =>
                                            HandeAddCodeToBody(
                                                readOnly,
                                                '[serviceStartingDateTime]',
                                                template,
                                                setTemplate
                                            )
                                        }
                                        label={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                            >
                                                [serviceStartingDateTime]
                                            </Typography>
                                        }
                                    />
                                    &nbsp; Will insert the service starting date
                                    and time if applicable.
                                </Typography>
                                <br />
                                <Typography variant="subtitle1" align="left">
                                    <Chip
                                        onClick={() =>
                                            HandeAddCodeToBody(
                                                readOnly,
                                                '[vehicleSaleNumber]',
                                                template,
                                                setTemplate
                                            )
                                        }
                                        label={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                            >
                                                [vehicleSaleNumber]
                                            </Typography>
                                        }
                                    />
                                    &nbsp; Will insert the vehicle sale number
                                    if applicable.
                                </Typography>
                                <br />
                                <Typography variant="subtitle1" align="left">
                                    <Chip
                                        onClick={() =>
                                            HandeAddCodeToBody(
                                                readOnly,
                                                '[onlineOrderNumber]',
                                                template,
                                                setTemplate
                                            )
                                        }
                                        label={
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                            >
                                                [onlineOrderNumber]
                                            </Typography>
                                        }
                                    />
                                    &nbsp; Will insert the online order number
                                    if applicable.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>

            {id ? (
                <Fab
                    loading={loading}
                    editIcon={readOnly}
                    onClick={() =>
                        readOnly
                            ? setReadOnly(false)
                            : UpdateTemplate(
                                  parseInt(id),
                                  templateType,
                                  template,
                                  setLoading,
                                  setReadOnly,
                                  showSnackbar,
                                  setTemplateError
                              )
                    }
                />
            ) : (
                <Fab
                    loading={loading}
                    editIcon={false}
                    onClick={() =>
                        AddNewTemplate(
                            template,
                            setLoading,
                            setReadOnly,
                            showSnackbar,
                            setTemplateError
                        )
                    }
                />
            )}
        </PageWrapper>
    );
};

export default withSnackbar(ViewTemplate);
