import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import AddSigner from './AddSigner';
import UploadAndTag from './UploadAndTag';
import { Signer } from '../EsignInterface';
import { useHistory } from 'react-router-dom';
import CheckCurrentUserSignature from '../../global/databaseLogic/CheckCurrentUserSignature';
import { User } from '../../global/interfaces/GeneralInterface';
import { UserContext } from '../../../main/Main';

const CreateSignTabContent = ({ showSnackbar }) => {
    const currentUser = useContext<User>(UserContext);

    const [activeStep, setActiveStep] = useState(0);

    const [selectedSigners, setSelectedSigners] = useState<Signer[]>([]);

    const [signatureDataUrl, setSignatureDataUrl] = useState<string | null>(
        null
    );
    const history = useHistory();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        CheckCurrentUserSignature({ currentUser, showSnackbar, history })
            .then((base64Image) => {
                setSignatureDataUrl(base64Image as string);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [currentUser, showSnackbar, history]);

    if (signatureDataUrl === null) {
        return null;
    }

    return (
        <Grid
            container
            spacing={2}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Grid item xs={12}>
                {activeStep === 0 && (
                    <AddSigner
                        selectedSigners={selectedSigners}
                        setSelectedSigners={setSelectedSigners}
                        handleNext={handleNext}
                        showSnackbar={showSnackbar}
                    />
                )}

                {activeStep === 1 && (
                    <UploadAndTag
                        selectedSigners={selectedSigners}
                        signatureDataUrl={signatureDataUrl}
                        handleBack={handleBack}
                        showSnackbar={showSnackbar}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default CreateSignTabContent;
