import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TagWrapper from './TagWrapper';
import { Tag, TAG_ACTIONS } from '../../EsignInterface';

interface SignTagProps {
    action: string;
    tag?: any;
    fontSize?: string;
    removeTag?: () => void;
    setTags?: React.Dispatch<React.SetStateAction<Tag[]>>;
    signatureDataUrl?: any;
    setSignatureDataUrl?: React.Dispatch<any>;
}

const MySignatureTag = ({
    action,
    tag,
    fontSize,
    removeTag,
    setTags,
    signatureDataUrl,
    setSignatureDataUrl
}: SignTagProps) => {
    const isResizable = action === TAG_ACTIONS.MODIFY;
    const isViewOnly = action === TAG_ACTIONS.VIEW;

    const dragStartHandler = (event) => {
        const rect = event.target.getBoundingClientRect();

        // calculate the offset of the mouse cursor from the top left corner
        const offsetX = event.clientX - rect.left;
        const offsetY = event.clientY - rect.top;

        // get the signature field size
        const width = rect.width;
        const height = rect.height;

        // set the data transfer object for dragging
        event.dataTransfer.setData('type', `mySignature`);
        event.dataTransfer.setData('offsetX', offsetX.toString());
        event.dataTransfer.setData('offsetY', offsetY.toString());
        event.dataTransfer.setData('width', width.toString());
        event.dataTransfer.setData('height', height.toString());

        // if tag is not undefined, means the tag is already in the canvas, set the tempId to the dataTransfer
        event.dataTransfer.setData(
            'tempId',
            tag !== undefined ? tag.tempId : ''
        );
    };

    return (
        <>
            <TagWrapper
                isViewOnly={isViewOnly}
                isResizable={isResizable}
                dragStartHandler={dragStartHandler}
            >
                {isResizable && removeTag ? (
                    <>
                        {signatureDataUrl && (
                            <img
                                src={signatureDataUrl}
                                alt="signature"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain'
                                }}
                            />
                        )}

                        <IconButton
                            onClick={removeTag}
                            size="small"
                            sx={{
                                color: 'primary.main'
                            }}
                        >
                            <CloseIcon sx={{ fontSize: fontSize }} />
                        </IconButton>
                    </>
                ) : (
                    <>
                        <Typography
                            sx={{
                                fontSize: fontSize,
                                flexGrow: 0.8
                            }}
                        >
                            My Signature
                        </Typography>
                    </>
                )}
            </TagWrapper>
        </>
    );
};

export default MySignatureTag;
