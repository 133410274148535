import {
    AppBar,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    Grid,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import api from '../../../../api';
import { Close } from '@mui/icons-material';

const NewSignerAppBar = ({
    tags,
    canvasHeight,
    currentTagIndex,
    setCurrentTagIndex,
    setIsSubmitSuccess
}) => {
    const [isSending, setIsSending] = useState(false);
    const [sendingErrorMessage, setSendingErrorMessage] = useState(null);

    const handleClickStartOrNext = () => {
        // when haven't started; or when the current tag has inputData; can move to the next tag
        if (currentTagIndex === -1 || tags[currentTagIndex].inputData) {
            setCurrentTagIndex((prev) => prev + 1);
        }
    };

    // when click Submit, send the documentId, signerId and tags to the backend, backend will generate a new file with all the tag input data
    const handleClickSubmit = async () => {
        setIsSending(true);
        const res = await api.post('/generateSignedDocument', {
            documentId: tags[0].DocumentId,
            signerId: tags[0].SignerId,
            tags: tags
        });

        if (res.status === 200) {
            setIsSubmitSuccess(true);
            setIsSending(false);
        } else {
            console.error('Submit failed');
            setSendingErrorMessage(
                'Submit failed. Refresh the page and try again.'
            );
        }
    };

    const StartOrNextButton = () => {
        return (
            <Button
                style={{
                    backgroundColor: '#FFD700',
                    color: '#000000'
                }}
                variant="contained"
                onClick={handleClickStartOrNext}
            >
                {currentTagIndex === -1 ? 'Start' : 'Next'}
            </Button>
        );
    };

    const SubmitButton = () => {
        return (
            <Tooltip title="By clicking submit, a signed copy of the document will be emailed to you and the sender.">
                <Button
                    disabled={isSending}
                    style={{
                        backgroundColor: '#FFD700',
                        color: '#000000'
                    }}
                    variant="contained"
                    onClick={handleClickSubmit}
                >
                    Submit
                </Button>
            </Tooltip>
        );
    };

    // check if all required fields are signed; if it is not required, can be skipped
    // const [allRequiredCompleted, setAllRequiredCompleted] = useState(false);

    // useEffect(() => {
    //     // check if all tag has inputData, except for the checkbox, checkbox can be skipped
    //     const allRequiredCompleted = tags.every((tag) => tag.inputData);

    //     setAllRequiredCompleted(allRequiredCompleted);
    // }, [tags]);

    const isLastStep = currentTagIndex === tags.length - 1;

    return (
        <AppBar position="sticky" color="primary">
            <Toolbar
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px'
                }}
            >
                <Typography
                    variant="body1"
                    style={{
                        flexGrow: 1,
                        textAlign: 'left',
                        marginLeft: '5%'
                    }}
                >
                    {isLastStep
                        ? "You have successfully signed. Click the 'Submit' button to send the document."
                        : currentTagIndex === -1
                          ? "Please review and sign the document below. Click the 'Start' button to begin."
                          : "Click the 'Next' button to proceed to the next section."}
                </Typography>
                <div style={{ flexGrow: 0, marginRight: '2%' }}>
                    {isLastStep ? <SubmitButton /> : <StartOrNextButton />}
                </div>
            </Toolbar>
            <Dialog open={isSending}>
                <DialogContent>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            {sendingErrorMessage ? (
                                <Close sx={{ fontSize: 50, color: 'red' }} />
                            ) : (
                                <CircularProgress />
                            )}
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Typography variant="h6" gutterBottom>
                                {sendingErrorMessage ||
                                    'Please wait while we process your request'}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </AppBar>
    );
};

export default NewSignerAppBar;
