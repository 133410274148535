import React from 'react';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Tag, TAG_ACTIONS } from '../../EsignInterface';
import TagWrapper from './TagWrapper';

interface TextfieldTagProps {
    action: string;
    tag?: any;
    fontSize?: string;
    removeTag?: () => void;
    setTags?: React.Dispatch<React.SetStateAction<Tag[]>>;
}

const TextTag = ({
    action,
    tag,
    fontSize,
    removeTag,
    setTags
}: TextfieldTagProps) => {
    const isViewOnly = action === TAG_ACTIONS.VIEW;
    const isResizable = action === TAG_ACTIONS.MODIFY;

    const dragStartHandler = (event) => {
        const rect = event.target.getBoundingClientRect();

        // calculate the offset of the mouse cursor from the top left corner
        const offsetX = event.clientX - rect.left;
        const offsetY = event.clientY - rect.top;

        // get the signature field size
        const width = rect.width;
        const height = rect.height;

        // set the data transfer object for dragging
        event.dataTransfer.setData('type', 'text');
        event.dataTransfer.setData('offsetX', offsetX.toString());
        event.dataTransfer.setData('offsetY', offsetY.toString());
        event.dataTransfer.setData('width', width.toString());
        event.dataTransfer.setData('height', height.toString());
        event.dataTransfer.setData(
            'inputData',
            tag !== undefined ? tag.inputData : ''
        );

        // if tag is not undefined, means the tag is already in the canvas, set the tempId to the dataTransfer
        event.dataTransfer.setData(
            'tempId',
            tag !== undefined ? tag.tempId : ''
        );
    };

    const handleTextFieldChange = (e) => {
        setTags((prevTags) => {
            return prevTags.map((t) => {
                if (t.tempId === tag.tempId) {
                    return { ...t, inputData: e.target.value };
                }
                return t;
            });
        });
    };

    return (
        <TagWrapper
            isViewOnly={isViewOnly}
            isResizable={isResizable}
            dragStartHandler={dragStartHandler}
        >
            {isResizable ? (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    <TextField
                        placeholder="Add text..."
                        fullWidth
                        variant="standard"
                        InputProps={{
                            style: {
                                fontSize: fontSize
                            }
                        }}
                        value={tag.inputData}
                        sx={{
                            '& .MuiInput-underline:before': {
                                borderBottom: 'none'
                            },
                            '& .MuiInput-underline:hover:before': {
                                borderBottom: 'none !important'
                            },
                            '& .MuiInput-underline:after': {
                                borderBottom: 'none'
                            }
                        }} // remove the underline
                        onChange={handleTextFieldChange}
                    />
                    <IconButton
                        onClick={removeTag}
                        size="small"
                        sx={{
                            color: 'primary.main'
                        }}
                    >
                        <CloseIcon sx={{ fontSize: fontSize }} />
                    </IconButton>
                </Box>
            ) : (
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: fontSize,
                        flexGrow: 0.8
                    }}
                >
                    Add Text
                </Typography>
            )}
        </TagWrapper>
    );
};

export default TextTag;
